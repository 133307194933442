import { render, staticRenderFns } from "./default.vue?vue&type=template&id=576c5719&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=576c5719&prod&lang=css"
import style1 from "./default.vue?vue&type=style&index=1&id=576c5719&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576c5719",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarVersionChip: require('/usr/src/app/components/navbar/VersionChip.vue').default,NavbarChangeLanguage: require('/usr/src/app/components/navbar/ChangeLanguage.vue').default,NavbarDarkModeSwitch: require('/usr/src/app/components/navbar/DarkModeSwitch.vue').default,NavbarNotificationList: require('/usr/src/app/components/navbar/NotificationList.vue').default,NavbarMessageDropdown: require('/usr/src/app/components/navbar/MessageDropdown.vue').default,NavbarProfileDropdown: require('/usr/src/app/components/navbar/ProfileDropdown.vue').default,IsOffline: require('/usr/src/app/components/IsOffline.vue').default,BreadCrumbs: require('/usr/src/app/components/BreadCrumbs.vue').default,LoadingLanguageString: require('/usr/src/app/components/LoadingLanguageString.vue').default,QuotaWarning: require('/usr/src/app/components/QuotaWarning.vue').default,CustomToaster: require('/usr/src/app/components/CustomToaster.vue').default})
